@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

*{
    box-sizing: border-box;
}
body{
    padding: 0;
    margin: 0;
    min-height: 100vh;
    position: relative;
    background-color: #fefefe;
    font-family: 'Open Sans', sans-serif;
}
.App{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.navbar{
    top: 0;
    left: 0;
    margin: 0;
    width: 100%;
    z-index: 10;
    gap: 0 20px;
    height: 60px;
    display: flex;
    padding: 0 30px;
    list-style: none;
    position: absolute;
    align-items: center;
    box-shadow: 0 0 10px #ccc;
    justify-content: space-around;
    background-color: #ff8d14;
    background-image: linear-gradient(62deg, #ff8d14 0%, #db7100 100%);
}
.navbar a{
    text-decoration: none;
}
.navbar .logo{
    gap: 0 12px;
    height: 46px;
    display: flex;
    padding: 5px 10px;
    align-items: center;
    border-radius: 10px;
    background: white;
}
.navbar .logo .url{
    color: black;
    text-transform: uppercase;
    text-decoration: none;
}
.navbar .logo svg{
    width: auto;
    height: 100%;
}
.navbar .logo svg path{
    stroke: black;
}
.navbar .right{
    gap: 20px;
    display: flex;
    align-items: center;
}
.navbar .right a{
    color: #fff;
    font-size: 15px;
}
.navbar .right a.action{
    color: #c76700;
    background: #fff;
    padding: 8px 15px;
    border-radius: 5px;
}
.main{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer{
    width: 100%;
    display: flex;
    color: #fff;
    padding: 30px 0;
    margin: 80px 0 0 0;
    background: #1b1b1b;
    justify-content: space-evenly;
}
.footer .header{
    width: 100%;
    font-weight: bold;
    font-size: 20px;
}
.footer .about{
    gap: 8px 0;
    width: 450px;
    display: flex;
    font-size: 14px;
    flex-direction: column;
}
.footer .navigation{
    gap: 8px 0;
    display: flex;
    font-size: 14px;
    flex-direction: column;
}
.footer .navigation ul{
    margin: 0;
    padding: 0;
    text-align: left;
    list-style: none;
}
.footer .navigation ul a{
    color: #fff;
}