.form{
    gap: 30px 10px;
    width: 600px;
    padding: 20px 30px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    box-shadow: 0 0 15px #d7d7d7;
    background: #fff;
}
.form .arrowSeparator{
    width: 30px;
    height: 30px;
    flex: 0 0 30px;
    fill: #ff8d14;
}
.form .step{
    width: 100%;
    gap: 15px 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
.form .step .description{
    width: 100%;
    font-size: 14px;
    text-align: center;
    color: #1b1b1b;
}
.form .step select{
    border: none;
    font-size: 1rem;
    padding: 5px 10px;
    text-align: center;
    background-color: #f5f5f5;
    border-bottom: 2px #ff8d14 solid;
}
.form .step .textareas{
    gap: 0 10px;
    display: flex;
    min-height: 80px;
}
.form .step .textareas textarea{
    border: none;
    font-size: 1rem;
    padding: 5px;
    resize: vertical;
    background-color: #f5f5f5;
    border-bottom: 2px #ff8d14 solid;
}
.form .step .source{
    width: 200px;
    border: none;
    background: none;
    padding: 7px 10px;
    background: #f5f5f5;
    border-bottom: 2px #ff8d14 solid;
}

.form .step .limits{
    width: 100%;
    display: flex;
    gap: 0 25px;
    align-items: center;
}
.form .step .limits .add{
    display: flex;
    gap: 5px 0;
    flex: 0 0 35%;
    flex-direction: column;
}
.form .step .limits .add input[type="number"],
.form .step .limits .add input[type="text"]{
    width: 100%;
    border: none;
    background: none;
    padding: 5px 7px;
    background: #f5f5f5;
    border-bottom: 2px #ff8d14 solid;
}
.form .step .limits .add input[type="color"],
.form .step .selectMainColor{
    padding: 0;
    width: 100%;
    max-width: 200px;
    border: none;
    height: 30px;
    background: none;
    position: relative;
    cursor: pointer;
}
.form .step .limits .add input[type="color"]::-webkit-color-swatch,
.form .step .selectMainColor::-webkit-color-swatch{
    padding: 1px 0;
    border-radius: 5px;
    border: 1px #ff8d14 solid;
}
.form .step .limits .add input[type="color"]::-webkit-color-swatch-wrapper,
.form .step .selectMainColor::-webkit-color-swatch-wrapper{
    padding: 1px 0;
    border-radius: 5px;
}
.form .step .limits .add input[type="color"]::after,
.form .step .selectMainColor::after{
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    display: flex;
    color: #ffffff;
    position: absolute;
    align-items: center;
    font-size: 12px;
    font-weight: bold;
    justify-content: center;
    content: 'Wybierz kolor';
}
.form .step .limits .add button{
    padding: 5px 0;
    width: 100%;
    border: none;
    transition: .2s;
    cursor: pointer;
    background: #f5f5f5;
    border: 2px #ff8d14 solid;
}
.form .step .limits .add button:hover{
    background: #fff;
}
.form .step .limits .list{
    gap: 5px 0;
    display: flex;
    height: 100%;
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    background-color: #f5f5f5;
    flex-direction: column;
}
.form .step .limits .list .limit{
    gap: 0 5px;
    align-items: center;
    display: flex;
}
.form .step .limits .list .limit .numbers{
    display: flex;
    align-items: center;
    font-size: 13px;
}
.form .step .limits .list .limit .remove{
    border: none;
    padding: 0;
    width: 20px;
    height: 20px;
    cursor: pointer;
    background: none;
}
.form .step .limits .list .limit .remove svg{
    width: 20px;
    height: 20px;
    fill: red;
}
.form .navigation{
    width: 100%;
    display: flex;
    gap: 0 20px;
    justify-content: center;
}
.form .navigation button{
    cursor: pointer;
    padding: 6px 15px;
    font-size: 16px;
    color: #d16c00;
    transition: .2s;
    background: #ffffff;
    border: 2px #ff8d14 solid;
}
.form .navigation button:disabled{
    opacity: .5;
    pointer-events: none;
}
.form .navigation button:not(:disabled):hover{
    color: #ffffff;
    background: #ff8d14;
}

.titleInvalid{
    padding: 10px 20px;
    color: #fff;
    background: red;
}