.preview{
    display: flex;
    gap: 45px 0;
    flex-direction: column;
}
.mapContainer{
    display: flex;
    gap: 30px 0;
    margin: 90px 0 0 0;
    align-items: center;
    flex-direction: column;
}
.App.iframe .mapContainer{
    margin: 0;
}
.mapContainer.preview{
    padding: 25px 30px;
    background-color: #fff;
    box-shadow: 0 0 40px 10px #00000029;
}
@keyframes spin {
    from {transform: rotate(0deg);}
    to {transform: rotate(360deg);}
}
.mapContainer.preview .loading{
    padding: 100px 80px;
}
.mapContainer.preview .loading svg{
    animation: spin 0.8s infinite;
}
.App.iframe .mapContainer.preview{
    padding: 0;
    box-shadow: unset;
}
.leftMenu{
    left: 0;
    top: 80px;
    bottom: 20px;
    width: 250px;
    display: flex;
    gap: 7px 0;
    padding: 15px;
    background: #fefefe;
    height: calc(100vh - 100px);
    box-shadow: 0 0 30px #e4e4e4;
    border-radius: 0 10px 10px 0;
    overflow: hidden;
    position: absolute;
    flex-direction: column;
}
.leftMenu .header{
    margin: 0;
    width: 100%;
    margin: 0 0 10px 0;
    font-size: 16px;
    text-align: center;
    font-weight: normal;
}
.leftMenu .accuracy{
    border: none;
    padding: 8px 0;
    background: #f68812;
    cursor: pointer;
    border-radius: 5px;
    color: #fff;
    font-size: 15px;
}

.map{ 
    display: flex;
    gap: 8px 0;
    flex-direction: column;
    position: relative;
}
.map .title{
    width: 100%;
    font-size: 20px;
    text-align: center;
}
.map .mapTitle{
    width: 100%;
    border: none;
    font-size: 20px;
    padding: 5px;
    resize: none;
    overflow: hidden;
    background: transparent;
    border-radius: 5px;
    word-wrap: break-word;
    text-align: center;
    font-family: inherit;
}
.map .mapTitle:focus{
    resize: vertical;
    outline: 2px #555 solid;
}
.map .mapSvg{
    position: relative;
}
.map .mapSvg .titleBox{
    top: 0;
    left: 0;
    padding: 5px;
    color: #fff;
    font-size: 13px;
    position: absolute;
    pointer-events: none;
    background: #2b2b2b;
    border: 1px solid #050505;
    border-radius: 0 5px 5px 5px;
}
.map .mapSvg svg{
    width: 500px;
    height: 500px;
    fill: #dedede;
    stroke: white;
    stroke-width: 1px;
}
.map .mapSvg svg path:hover{
    fill: #cdcdcd;
}
.bar{
    width: 20px;
    height: 100px;
    background-image: linear-gradient(to bottom, rgba(255, 0, 0, 1), rgba(255, 0, 0, 0));
}
.map .bottom-left{
    left: 0;
    bottom: 0;
    gap: 10px 0;
    display: flex;
    flex-direction: column;
    position: absolute;
}
.map .bottom-left .legend{
    gap: 5px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.map .legend .label{
    gap: 0 5px;
    display: flex;
}
.map .legend .label .labelText{
    max-width: 200px;
}
.map .legend .label .color{
    width: 15px;
    height: 15px;
}
.map .source{
    font-size: 12px;
    color: #3f3f3f;
}

.insertIframe{
    display: flex;
    align-items: center;
    background: #ececec;
    overflow: hidden;
    border-radius: 5px;
}
.App.iframe .insertIframe{
    display: none;
}
.insertIframe .label{
    color: #fff;
    padding: 5px 9px;
    font-size: 14px;
    background: #2b2b2b;
}
.insertIframe .url{
    padding: 0 6px;
    max-width: 430px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.insertIframe .copy{
    border: none;
    cursor: pointer;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    background: #d3d3d3;
}
.insertIframe .copy svg{
    width: 18px;
    fill: #000;
}